export const cities = [
  { id: 3385, value: "BOROWO-MŁYN" },
  { id: 3282, value: "BARCINEK" },
  { id: 3371, value: "BEDNARY" },
  { id: 3279, value: "BISKUPICE" },
  { id: 3307, value: "BOCINIEC" },
  { id: 3383, value: "BORÓWKO" },
  { id: 1242, value: "BUGAJ" },
  { id: 3286, value: "CZACHURKI" },
  { id: 3351, value: "GORZKIE POLE" },
  { id: 3451, value: "GOŁUNIN" },
  { id: 3378, value: "GOŁUŃ" },
  { id: 3337, value: "GÓRA" },
  { id: 3316, value: "GŁÓWIENKA" },
  { id: 3291, value: "GŁÓWNA" },
  { id: 3689, value: "JANKOWO-MŁYN" },
  { id: 3464, value: "JANKOWO" },
  { id: 3273, value: "JERZYKOWO" },
  { id: 3348, value: "JERZYN" },
  { id: 3447, value: "JEZIERCE" },
  { id: 3429, value: "KACZYNA" },
  { id: 3311, value: "KAPALICA" },
  { id: 3376, value: "KOCANOWO" },
  { id: 3313, value: "KOCIAŁKOWA GÓRKA" },
  { id: 3303, value: "KOWALSKIE" },
  { id: 3323, value: "KOŁATA" },
  { id: 3441, value: "KOŁATKA" },
  { id: 3289, value: "KRZEŚLICE" },
  { id: 3538, value: "KURACZ" },
  { id: 3325, value: "LATALICE" },
  { id: 3402, value: "NADROŻNO" },
  { id: 3570, value: "NOWA GÓRKA" },
  { id: 3274, value: "POBIEDZISKA" },
  { id: 3389, value: "PODARZEWO" },
  { id: 3482, value: "POLSKA WIEŚ" },
  { id: 3413, value: "POMARZANKI" },
  { id: 3339, value: "POMARZANOWICE" },
  { id: 3302, value: "PROMIENKO" },
  { id: 3305, value: "PROMNO" },
  { id: 3424, value: "PROMNO-STACJA" },
  { id: 3342, value: "PRUSZEWIEC" },
  { id: 3487, value: "SIODŁOWO" },
  { id: 3284, value: "STARA GÓRKA" },
  { id: 3275, value: "STĘSZEWICE" },
  { id: 3335, value: "STĘSZEWKO" },
  { id: 3288, value: "TUCZNO" },
  { id: 3277, value: "UZARZEWO-HUBY" },
  { id: 3321, value: "WAGOWO" },
  { id: 3318, value: "WRONCZYN" },
  { id: 3292, value: "WRONCZYNEK" },
  { id: 3369, value: "WÓJTOSTWO" },
  { id: 3667, value: "WĘGLEWKO" },
  { id: 3373, value: "WĘGLEWO" },
  { id: 3503, value: "ZBIERKOWO" },
  { id: 3300, value: "ZŁOTNICZKI" },
  { id: 3294, value: "ŁAGIEWNIKI" },
];
