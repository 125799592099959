import { useState, ReactNode } from "react";
import { Box, Button } from "@chakra-ui/react";
import { BuyCoffeeButton } from "./BuyCoffeeButton";
import { When } from "react-if";

type Props = {
  name: string;
  title: string;
  buttonDescription?: string;
  buyCoffe?: boolean;
  children: ReactNode;
};

export function News({
  name,
  title,
  children,
  buyCoffe = false,
  buttonDescription = "Zamknij",
}: Props) {
  const [isCookieAccepted, setIsCookieAccepted] = useState<string | null>(
    () => {
      return localStorage.getItem(name);
    },
  );

  const onCookieAccept = () => {
    localStorage.setItem(name, "viewed");
    setIsCookieAccepted("viewed");
  };

  if (isCookieAccepted !== "viewed") {
    return (
      <Box
        mb="1rem"
        maxWidth="380px"
        backgroundColor="#293B58"
        color="#ffffff"
        borderRadius="0.5rem"
        p="1rem"
        style={{
          boxShadow: "0 3px 12px 0 rgba(0, 0, 0, 0.6)",
          position: "fixed",
          bottom: "1rem",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>{title}</h2>
        <Box>{children}</Box>
        <Box
          mt="1rem"
          display="flex"
          justifyContent="center"
          alignContent="space-between"
        >
          <When condition={buyCoffe}>
            <BuyCoffeeButton />
            <div style={{ marginRight: "4rem" }} />
          </When>
          <Button
            onClick={onCookieAccept}
            backgroundColor="#33b1b2"
            color="#ffffff"
          >
            {buttonDescription}
          </Button>
        </Box>
      </Box>
    );
  }

  return null;
}
