export const scheduleKey = "smieciarkajedzie_schedule";
export const addressKey = "smieciarkajedzie_schedule_address";
export const addressIdKey = "smieciarkajedzie_schedule_address_id";
export const buyButtonUrl = "https://buycoffee.to/janosio";

const mainURL = `https://hemar-harmonogram.smok.net.pl`;

export const streetsUrl = `${mainURL}/addresses/streets`;
export const numbersUrl = `${mainURL}/addresses/numbers`;

export const scheduleURL =
  `https://us-central1-smieciarka-jedzie-api-crawler.cloudfunctions.net/getSheduleById?uri=${mainURL}/reports?type=html`;