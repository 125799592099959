import { Box } from "@chakra-ui/react";
import styled from "styled-components";
import { THEME } from "../helpers/constans";
import { When } from "react-if";

import Truck from "../assets/garbageTruck.svg";
import TruckWithGuy from "../assets/today.svg";
import ChristmassTruckWithGuy from "../assets/lampeczki.svg";
import TruckWithLamps from "../assets/v2.svg";
import { useToday } from "../hooks/useToday";
import { Dayjs } from "dayjs";

type ScheduleItem = {
  date: string;
  types: string[];
};

type CalendarProps = {
  schedule: ScheduleItem[];
  today: Dayjs;
};

const DAYS = 7;
const days = Array(DAYS).fill(0);

export function Calendar({ schedule, today }: CalendarProps) {
  const { isDecember } = useToday();
  const dates = schedule.map((item: ScheduleItem) => item.date);

  return (
    <Box
      css={{
        marginTop: "3rem",
        position: "absolute",
        width: "calc(100% - 1rem)",
        overflow: "hidden",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        {days.map((_, index) => {
          const indexDay = today.add(index, "day");
          const formatedDay = indexDay.format("YYYY-MM-DD");
          const dayOfWeek = indexDay.format("dd");
          const dayOfWeekNumber = indexDay.format("DD");

          return (
            <CalendarCard key={formatedDay}>
              <When condition={dates.includes(formatedDay)}>
                <Car>
                  <img
                    width={index === 0 ? "34px" : "32px"}
                    height="34px"
                    alt="Śmieciarka"
                    src={getTruckType(index, isDecember)}
                  />
                </Car>
              </When>
              <DayOfWeek>{dayOfWeek}</DayOfWeek>
              <DayOfWeekNumber>{dayOfWeekNumber}</DayOfWeekNumber>
            </CalendarCard>
          );
        })}
      </Box>
    </Box>
  );
}

function getTruckType(index: number, isDecember: boolean): string {
  if (index === 0 && isDecember) {
    return ChristmassTruckWithGuy;
  } else if (index !== 0 && isDecember) {
    return TruckWithLamps;
  } else if (index === 0 && !isDecember) {
    return TruckWithGuy;
  } else {
    return Truck;
  }
}

const Car = styled.div`
  position: absolute;
  top: -25px;
`;

const DayOfWeek = styled.div`
  color: ${({ theme }: any) => theme.primary};
  font-weight: lighter;
  font-size: 14px;
`;

const DayOfWeekNumber = styled.div`
  font-size: 22px;
`;

const CalendarCard = styled.div`
  display: flex;
  padding: 1rem 1rem;
  margin-right: 0.75rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  border-radius: ${THEME.borderRadius};
  color: ${({ theme }: any) => theme.text};
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  position: relative;
  background: ${({ theme }: any) => theme.background2};
  &:first-of-type {
    border: none;
    background: ${({ theme }: any) => theme.primary};
    color: ${({ theme }: any) => theme.textReverse};
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
    ${DayOfWeek} {
      color: ${({ theme }: any) => theme.textReverse};
    }
  }
`;
