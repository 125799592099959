import { useMemo } from "react";
import dayjs from "dayjs";
import { Item } from "./Item";
import { Heading } from "@chakra-ui/react";
import { useTheme } from "styled-components";
import { EmptyStateTemplate } from "./EmptyStateTemplate";
import { Dayjs } from "dayjs";
import { If, Then, Else, When } from "react-if";

type ScheduleItem = {
  date: string;
  types: string[];
};

type ScheduleListProps = {
  schedule: ScheduleItem[];
  today: Dayjs;
};

export function ScheduleList({ schedule, today }: ScheduleListProps) {
  const theme = useTheme() as any;
  const months: string[] = [];

  const currentSchedule = useMemo(
    () =>
      schedule.filter(
        (item: ScheduleItem) =>
          dayjs.utc(item.date).diff(today, "days", true) >= -1,
      ),
    [schedule, today],
  );

  const isEmpty = useMemo(
    () => currentSchedule.length === 0,
    [currentSchedule],
  );

  return (
    <If condition={isEmpty}>
      <Then>
        <EmptyStateTemplate />
      </Then>
      <Else>
        <div style={{ paddingTop: "1rem", minHeight: "calc(100vh - 270px)" }}>
          {currentSchedule.map((item: ScheduleItem) => {
            const currentMonth = dayjs.utc(item.date).format("MMMM");
            const notHaveMonth = !months.includes(currentMonth);

            if (notHaveMonth) {
              months.push(currentMonth);
            }

            return (
              <>
                <When condition={notHaveMonth}>
                  <Heading
                    key={currentMonth}
                    mt={6}
                    color={theme.text}
                    as="h4"
                    size="xl"
                    fontWeight={300}
                  >
                    {currentMonth}
                  </Heading>
                </When>
                <div key={item.date}>
                  <Item
                    item={item}
                    today={today}
                    borderColor={theme.itemBorderColor}
                    styles={{ opacity: 0.5 }}
                  />
                </div>
              </>
            );
          })}
        </div>
      </Else>
    </If>
  );
}
