import { useState } from "react";
import { Box, Button, Heading } from "@chakra-ui/react";
import { When } from "react-if";

export function Cookies() {
  const [isCookieAccepted, setIsCookieAccepted] = useState<string | null>(
    () => {
      return localStorage.getItem("smieciarkajedzie_cookie_accepted");
    },
  );

  const onCookieAccept = () => {
    localStorage.setItem("smieciarkajedzie_cookie_accepted", "accepted");
    setIsCookieAccepted("accepted");
  };

  return (
    <When condition={isCookieAccepted !== "accepted"}>
      <Box
        mb="1rem"
        maxWidth="380px"
        backgroundColor="#293B58"
        color="#ffffff"
        borderRadius="0.5rem"
        p="1rem"
        style={{
          boxShadow: "0 3px 12px 0 rgba(0, 0, 0, 0.6)",
          position: "fixed",
          bottom: "1rem",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
        }}
      >
        <Heading size="lg">Chcesz ciasteczko? 🍪</Heading>
        <Box>
          Korzystanie z witryny smieciarkajedzie.com oznacza zgodę na
          wykorzystywanie plików cookies, z których niektóre mogą być już
          zapisane w folderze przeglądarki.
        </Box>
        <Box mt="1rem" display="flex" justifyContent="center">
          <Button
            onClick={onCookieAccept}
            backgroundColor="#33b1b2"
            color="#ffffff"
          >
            Akceptuję
          </Button>
        </Box>
      </Box>
    </When>
  );
}
