import EmptyState from "../assets/emptyState.svg";

export function EmptyStateTemplate() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "4rem",
        minHeight: "calc(100vh - 270px)",
      }}
    >
      <img width={240} src={EmptyState} alt="Brak aktualnego harmonogramu" />
      <p
        style={{
          fontSize: "14px",
          lineHeight: "18px",
          maxWidth: "70vw",
          textAlign: "justify",
          paddingTop: "2rem",
        }}
      >
        Aktualnie nie posiadamy dostępu do najnowszego harmonogramu wywozu
        odpadów. Pragniemy poinformować, że pracujemy nad aktualizacją danych,
        które wkrótce pojawią się w aplikacji.
      </p>
    </div>
  );
}
