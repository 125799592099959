import { createContext, useContext, ReactNode } from "react";
import { useGetSchedule } from "../hooks/useGetSchedule";

const ScheduleContext = createContext<any>(null);

export function useSchedule() {
  return useContext(ScheduleContext);
}

export function ScheduleProvider({ children }: { children: ReactNode }) {
  const schedule = useGetSchedule();

  return (
    <ScheduleContext.Provider value={schedule}>
      {children}
    </ScheduleContext.Provider>
  );
}
