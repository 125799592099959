import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { cities } from "../data/cities";
import {
  scheduleKey,
  addressKey,
  addressIdKey,
  streetsUrl,
  numbersUrl,
  scheduleURL,
} from "../helpers/config";

type City = {
  id: string | number;
  value: string;
};

type Street = City;
type Address = City;

export function useGetSchedule() {
  const [cityId, setCityId] = useState<string | null>(null);
  const [streets, setStreets] = useState<Street[] | null>(null);
  const [streetId, setStreetId] = useState<string | null>(null);
  const [streetName, setStreetName] = useState<string | null>(null);
  const [addresses, setAddresses] = useState<Address[] | null>(null);
  const [addressId, setAddressId] = useState<string | null>(
    () => localStorage.getItem(addressIdKey) || null
  );
  const [addressLabel, setAddressLabel] = useState<string | null>(null);
  const [fullAddress, setFullAddress] = useState<string | null>(
    () => localStorage.getItem(addressKey) || null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<any>(
    () => JSON.parse(localStorage.getItem(scheduleKey) ?? "[]")
  );

  const formattedCities = useMemo(
    () =>
      cities.map((item: City) => ({
        value: item.id,
        label: item.value,
      })),
    []
  );

  const formattedStreets = useMemo(
    () =>
      streets?.map((item: Street) => ({
        value: item.id,
        label: item.value,
      })) || null,
    [streets]
  );

  const formattedNumbers = useMemo(
    () =>
      addresses?.map((item: Address) => ({
        value: item.id,
        label: item.value,
      })) || null,
    [addresses]
  );

  const fetchStreets = useCallback(async () => {
    if (!cityId || addressId) return;
    try {
      const { data } = await axios.get(`${streetsUrl}/${cityId}`);
      setStreets(data);
    } catch {
      setError(true);
    }
  }, [cityId, addressId]);

  const fetchAddresses = useCallback(async () => {
    if (!cityId || !streetId || addressId) return;
    try {
      const { data } = await axios.get(`${numbersUrl}/${cityId}/${streetId}`);
      setAddresses(data);
    } catch {
      setError(true);
    }
  }, [cityId, streetId, addressId]);

  const fetchSchedule = useCallback(async () => {
    if (!addressId) return;
    setIsLoading(true);
    try {
      const { data } = await axios.get(scheduleURL, { params: { id: addressId } });
      setSchedule(data.data || []);
      localStorage.setItem(addressIdKey, addressId);

      if (streetName && addressLabel) {
        const fullAddr = `ul. ${streetName} ${addressLabel}`;
        localStorage.setItem(addressKey, fullAddr);
        setFullAddress(fullAddr);
      }

      resetState();
      setError(false);
    } catch {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [addressId, streetName, addressLabel]);

  useEffect(() => {
    fetchStreets();
  }, [fetchStreets]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule]);

  const resetState = useCallback(() => {
    setCityId(null);
    setStreetId(null);
    setAddressId(null);
    setAddresses(null);
  }, []);

  return {
    isLoading,
    fullAddress,
    schedule,
    error,
    cityId,
    formattedCities,
    formattedStreets,
    addresses,
    formattedNumbers,
    setAddressId,
    setAddressLabel,
    setCityId,
    setStreetId,
    setStreetName,
    setAddresses,
    resetState,
  };
}
