import styled from "styled-components";

export const Title = styled.div`
  font-weight: 300;
  color: ${({ theme }: any) => theme.textReverse};
  font-size: 15px;
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Loader = styled.div`
  text-align: center;
  width: 200px;
  height: 50px;
  background: #ffffff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
`;

export const BigDate = styled.div`
  font-size: 2rem;
  max-width: calc(100vw - 160px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: ${({ theme }: any) => theme.textReverse};
  margin-top: -10px;
`;
