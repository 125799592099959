import dayjs from "dayjs";
import { Box, Wrap, Button } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import styled, { useTheme } from "styled-components";
import { lightTheme } from "../theme";
import { GarbageIcon } from "./GarbageIcon";
import ICalendarLink from "react-icalendar-link";
import { daysLeftTo, getDaysLeftDescription } from "../helpers/helpers";
import { colors, GARBAGES, THEME } from "../helpers/constans";

type GarbageProps = {
  bgcolor: string;
};

const Garbage = styled.div<GarbageProps>`
  border-radius: ${THEME.borderRadius};
  color: ${(props) => (props.color ? props.color : lightTheme.textReverse)};
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  background: ${(props) => (props.bgcolor ? props.bgcolor : "white")};
`;

const generateEvent = (item: any, typesOfGarbage: any) => ({
  title: `Odbiór odpadów - ${typesOfGarbage
    .map((garbage: any) => GARBAGES[garbage])
    .join(", ")}`,
  description: typesOfGarbage
    .map((garbage: any) => GARBAGES[garbage])
    .join(", "),
  startTime: dayjs(item.date).hour(6).format(),
  endTime: dayjs(item.date).hour(8).format(),
  location: "",
});

const renderGarbageIcons = (typesOfGarbage: any) =>
  typesOfGarbage.map((garbage: any) => (
    <GarbageIcon key={garbage} garbage={garbage} />
  ));

const renderGarbageLabels = (typesOfGarbage: any) =>
  typesOfGarbage.map((garbage: any) => (
    <Garbage
      key={garbage}
      color={garbage === "PLASTIC" ? lightTheme.text : lightTheme.textReverse}
      bgcolor={colors[garbage]}
    >
      {GARBAGES[garbage]}
    </Garbage>
  ));

export const Item = ({ item, borderColor, today }: any) => {
  const daysLeft = daysLeftTo(item.date, today);
  const theme = useTheme() as any;
  const typesOfGarbage = item.types.sort((a: string, b: string) =>
    a.localeCompare(b),
  );
  const event = generateEvent(item, typesOfGarbage);

  return (
    <Box
      borderWidth="1px"
      backgroundColor={theme.background2}
      borderColor={borderColor}
      borderRadius={THEME.borderRadius}
      mt="3"
      mb="3"
    >
      <Box pl="3" pr="3" mt="3" mb="3" fontWeight="semibold" isTruncated>
        <Box color={theme.text} display="flex">
          <div style={{ flex: 1 }}>
            {getDaysLeftDescription(daysLeft)}
            <div style={{ fontSize: "14px", opacity: "0.5" }}>
              {dayjs(item.date).format("D MMMM, dddd")}
            </div>
          </div>
          <GarbageIconContainer>
            {renderGarbageIcons(typesOfGarbage)}
          </GarbageIconContainer>
        </Box>
      </Box>
      <Box display="flex" alignItems="baseline" p="3">
        <Wrap>{renderGarbageLabels(typesOfGarbage)}</Wrap>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: ".25rem",
        }}
      >
        {/* @ts-ignore */}
        <ICalendarLink event={event}>
          <Button
            size="xs"
            color={lightTheme.textLight}
            background={lightTheme.background2}
          >
            <CalendarIcon mr={1} /> Dodaj do kalendarza
          </Button>
        </ICalendarLink>
      </div>
    </Box>
  );
};

const GarbageIconContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
`;
