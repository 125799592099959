export const lightTheme = {
  primary: "#ea8536",
  link: "#2B8F98",
  header: "#2B8F98",
  background: "#E4EFF1",
  background2: "#ffffff",
  text: "#1c2a3b",
  textLight: "#9a9a9a",
  textReverse: "#ffffff",
  itemBorderColor: "#ededed",
  calendarCellHeader: "#2B8F98",
  calendarCellCurrent: "#ea8536",
  red: "red",
};

export const darkTheme = {
  primary: "#ea8536",
  link: "#2B8F98",
  header: "#2B8F98",
  background: "#E4EFF1",
  background2: "#ffffff",
  text: "#1c2a3b",
  textLight: "#9a9a9a",
  textReverse: "#ffffff",
  itemBorderColor: "#ededed",
  calendarCellHeader: "#2B8F98",
  calendarCellCurrent: "#ea8536",
  red: "red",
};
