import dayjs, { Dayjs } from "dayjs";

import january from "../assets/months/1.png";
import february from "../assets/months/2.png";
import march from "../assets/months/3.png";
import april from "../assets/months/4.png";
import may from "../assets/months/5.png";
import june from "../assets/months/6.png";
import july from "../assets/months/7.png";
import august from "../assets/months/8.png";
import september from "../assets/months/9.png";
import october from "../assets/months/10.png";
import november from "../assets/months/11.png";
import december from "../assets/months/12.png";

export function getDaysLeftDescription(number: number) {
  if (number <= 0) {
    return "Dzisiaj";
  }
  if (number <= 1) {
    return "Jutro";
  }
  return `Za ${number.toFixed()} dni`;
}

export function daysLeftTo(date: string, today: Dayjs) {
  return Math.ceil(dayjs.utc(date).diff(today, "days", true));
}

export function getMonthImage(month: number) {
  if (month === 1) {
    return january;
  }
  if (month === 2) {
    return february;
  }
  if (month === 3) {
    return march;
  }
  if (month === 4) {
    return april;
  }
  if (month === 5) {
    return may;
  }
  if (month === 6) {
    return june;
  }
  if (month === 7) {
    return july;
  }
  if (month === 8) {
    return august;
  }
  if (month === 9) {
    return september;
  }
  if (month === 10) {
    return october;
  }
  if (month === 11) {
    return november;
  }
  if (month === 12) {
    return december;
  }
}
