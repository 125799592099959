type GarbageProps = {
  [key: string]: string;
};

export const GARBAGES: GarbageProps = {
  MIXED: "Zmieszane",
  GLASS: "Szkło",
  BIO: "Bioodpady",
  PAPER: "Papier",
  PLASTIC: "Metale i tworzywa sztuczne",
  TREE: "Drzewka świąteczne",
  BIG: "Odpady wystawkowe",
} as const;

export const THEME = {
  borderRadius: ".5rem",
} as const;

export const colors: GarbageProps = {
  MIXED: "#000000",
  GLASS: "#33b28c",
  BIO: "#b27033",
  PAPER: "#3394b2",
  PLASTIC: "#ffcb10",
  TREE: "red",
  BIG: "teal",
} as const;
