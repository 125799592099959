import caffeeButton from "../assets/buycoffeeto-btn-primary.svg";
import { Button } from "@chakra-ui/react";
import { buyButtonUrl } from "../helpers/config";

function onBuyCoffee() {
  window.open(buyButtonUrl, "_blank");
}

export function BuyCoffeeButton() {
  return (
    <Button
      style={{ width: "132px", backgroundColor: "transparent" }}
      m={0}
      p={0}
      onClick={onBuyCoffee}
    >
      <img
        src={caffeeButton}
        style={{ width: "132px" }}
        alt="Postaw mi kawę na buycoffee.to"
      />
    </Button>
  );
}
