import { GARBAGES } from "../helpers/constans";
import Trash6 from "../assets/trash6.svg";
import Trash5 from "../assets/trash5.svg";
import Trash3 from "../assets/trash3.svg";
import Trash4 from "../assets/trash4.svg";
import Trash2 from "../assets/trash2.svg";
import Tree from "../assets/tree.svg";
import Armchair from "../assets/armchair.svg";

const GARBAGE_IMAGES = {
  MIXED: { src: Trash6, alt: GARBAGES.MIXED },
  BIO: { src: Trash5, alt: GARBAGES.BIO },
  PAPER: { src: Trash3, alt: GARBAGES.PAPER },
  PLASTIC: { src: Trash4, alt: GARBAGES.PLASTIC },
  GLASS: { src: Trash2, alt: GARBAGES.GLASS },
  TREE: { src: Tree, alt: GARBAGES.TREE },
  BIG: { src: Armchair, alt: GARBAGES.BIG },
} as const;

enum Garbage {
  MIXED = "MIXED",
  BIO = "BIO",
  PAPER = "PAPER",
  PLASTIC = "PLASTIC",
  GLASS = "GLASS",
  TREE = "TREE",
  BIG = "BIG",
}

type GarbageType = {
  garbage: Garbage;
};

export function GarbageIcon({ garbage }: GarbageType) {
  const garbageInfo = GARBAGE_IMAGES[garbage];

  if (garbageInfo) {
    const { src, alt } = garbageInfo;

    return <img key={garbage} width="32px" height="32px" alt={alt} src={src} />;
  }

  return null;
}
