import { useSunsetSunrise } from "../hooks/useSunsetSunrise";

import Sunset from "../assets/sunset.svg";
import Sunrise from "../assets/sunrise.svg";

export const Sun = () => {
  const { sunset, sunrise } = useSunsetSunrise();

  return (
    <div
      style={{
        display: "flex",
        fontSize: "12px",
        margin: "-6px 0 12px 0",
        padding: 0,
        color: "white",
      }}
    >
      <img
        width="16px"
        src={Sunrise}
        alt="sunrise"
        style={{ marginRight: "6px" }}
      />
      {sunrise}{" "}
      <img
        width="16px"
        src={Sunset}
        alt="sunset"
        style={{ marginLeft: "16px", marginRight: "6px" }}
      />
      {sunset}
    </div>
  );
};
